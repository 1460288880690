<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <!-- Processo -->
              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!--      &lt;!&ndash; Status Filter&ndash;&gt;-->
      <!--      <div class="col-12 mb-3">-->
      <!--        <div class="card no-bottom-margin">-->
      <!--          <div class="section-sub-menu">-->
      <!--            <div class="section-sub-menu">-->
      <!--              <ul class="nav line-tabs borderless flex-center">-->
      <!--                <li class="nav-item">-->
      <!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"-->
      <!--                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>-->
      <!--                </li>-->
      <!--                <li class="nav-item">-->
      <!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 0 }"-->
      <!--                     v-on:click="applyStatusFilter(0)" data-toggle="tab">Pendente</a>-->
      <!--                </li>-->
      <!--                <li class="nav-item">-->
      <!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"-->
      <!--                     v-on:click="applyStatusFilter(1)" data-toggle="tab">Sucesso</a>-->
      <!--                </li>-->
      <!--                <li class="nav-item">-->
      <!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 4 }"-->
      <!--                     v-on:click="applyStatusFilter(4)" data-toggle="tab">Erro</a>-->
      <!--                </li>-->
      <!--              </ul>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <custom-vue-table
                  ref="OportunidadesTable"
                  http-method="get"
                  :api-url="listEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="activeFilters"
                  track-by="id"
                >
                </custom-vue-table>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import TaskLog from '@/views/Schedules/TaskLog.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomVueTable from '@/components/Table/CustomVueTable'

// Components
import AddInfo from '@/views/Tools/Oportunidade/AddInfo'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaskService from '@/services/TaskService'
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'
import WebhookService from '@/services/WebhookService'

export default {
  name: 'WebHookLogsList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('oportunidades.title') + ' - %s'
    }
  },
  data () {
    return {
      taskId: null,
      userList: [],
      employeeList: [],
      customerList: [],
      isLoading: false,
      fullPage: true,
      activeFilterList: {
        id_status: null
      },
      filter: {},
      sortOrder: [
        { field: 'dataSolicitacao', direction: 'desc' }
      ]
    }
  },
  components: {
    CustomVueTable,
    FilterSideBar,
    Loading,
    FilterContainer
  },
  // Apply filters to local filter
  beforeMount () {
    this.taskId = this.$route.params.id_schedule
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process
    this.filter.id_status = null
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    onForceSync (id) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza Sincronizar.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => _this.forceSync(id)).then((result) => {
      })
    },
    async forceSync (id) {
      let _this = this
      this.isLoading = true
      WebhookService.forceSync(id).then(res => {
        _this.isLoading = false
      }).finally(() => {
        _this.isLoading = false
        _this.$router.push({ name: 'WebhooksLog', params: { id: id } })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    userFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getUsuarios(filters).then(res => {
          _this.userList = res.data.data
          resolve(_this.userList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getFuncionarios(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    showModal () {
      this.$modal.show(TaskLog, {}, {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '80%',
        height: 'auto'
      })
    },
    // Delete
    onDelete (task) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir esta tarefa.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(task)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Tarefa excluída com sucesso!')
        }
      })
    },
    async delete (task) {
      let _this = this
      _this.isLoading = true

      await TaskService.deleteTask(task.id).then(task => {
        _this.isLoading = false
        this.$refs.OportunidadesTable.refresh()
      })
    },
    // Force Send
    onSendNow (task) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja enviar esse tarifário agora.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.sendNow(task)).then((result) => {
        if (result.value) {
          _this.$alertSwal.toast('Tarifário adicionado na fila de envio!')
        }
      })
    },
    async sendNow (task) {
      let _this = this
      _this.isLoading = true
      await TaskService.forceSendTask(task.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // Send Test
    onSendTestNow (task) {
      let _this = this
      _this.sendTestNow(task).then((result) => {
        _this.$alertSwal.toast('Tarifário adicionado na fila de envio!')
      })
    },
    async sendTestNow (task) {
      let _this = this
      _this.isLoading = true
      await TaskService.testSendTask(task.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    mountLinkCopy (itemName, object) {
      return this.$router.resolve({ name: itemName, query: { id: object.id } }).href
    },
    buildFilters () {
      if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.OportunidadesTable.refresh()
    },
    // Loader
    applyFilter () {
      this.filters.filProcesso = this.$refs.internal_code.value
      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.OportunidadesTable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filProcesso = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.OportunidadesTable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      /*
          const TYPE_STATUS_WAITING = 0;
    const TYPE_STATUS_RUNNING = 1;
    const TYPE_STATUS_SUCCESS = 2;
    const TYPE_STATUS_CANCEL = 3;
    const TYPE_STATUS_ERROR = 4;
    const TYPE_STATUS_STOPPED = 5;
       */
      let statusList = [
        {
          'id': 0,
          'label': 'Pendente',
          'color': '#ababab'
        },
        {
          'id': 1,
          'label': 'Criando tarifários',
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': 'Sucesso',
          'color': '#5ec65f'
        },
        {
          'id': 3,
          'label': 'Cancelado',
          'color': '#F3614E'
        },
        {
          'id': 4,
          'label': 'Erro',
          'color': '#F3614E'
        },
        {
          'id': 5,
          'label': 'Parado',
          'color': '#F3614E'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$router.push({ name: 'ScheduleEdit', params: { id_schedule: row.data.id } })
    },
    changeStatus (rowData) {
      let _this = this
      this.$modal.show(AddInfo, { modalData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': _this.onCloseModal
        }
      )
    },
    onCloseModal () {
      this.$refs.OportunidadesTable.refresh()
    }
  },
  computed: {
    listEndpoint () {
      return process.env.VUE_APP_APIENDPOINT + 'v2/task/' + this.taskId + '/tariff/bulk/log'
    },
    activeFilters () {
      return this.filter
    },
    fieldsTable () {
      return [
        {
          name: 'dataSolicitacao',
          title: 'Data Solicitação',
          sortField: 'dataSolicitacao',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'solicitadoPor',
          title: 'Solicitado Por',
          sortField: 'solicitadoPor',
          formatter: (value) => this.$util.formatUser(value)
        },
        {
          name: 'nClientes',
          title: 'N. Clientes',
          sortField: 'nClientes',
          formatter: (value) => this.$util.formatNumber(value, 0)
        },
        {
          name: 'nContatos',
          title: 'N. Contatos',
          sortField: 'nContatos',
          formatter: (value) => this.$util.formatNumber(value, 0)
        },
        {
          name: 'situacao',
          title: 'Situação',
          sortField: 'situacao',
          formatter: (value) => this.formatStatus(value)
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isPricing () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_PRICING') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
.swal2-popup {
  width: inherit !important;
}

.btn-padding {
  padding: 0.55rem 0.30rem
}
</style>
